// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-contentful-blog-title-js": () => import("./../../../src/pages/blog/{ContentfulBlog.title}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-title-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-how-does-it-work-index-js": () => import("./../../../src/pages/how-does-it-work/index.js" /* webpackChunkName: "component---src-pages-how-does-it-work-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordering-payment-index-js": () => import("./../../../src/pages/ordering/payment/index.js" /* webpackChunkName: "component---src-pages-ordering-payment-index-js" */),
  "component---src-pages-ordering-review-order-end-index-js": () => import("./../../../src/pages/ordering/review-order-end/index.js" /* webpackChunkName: "component---src-pages-ordering-review-order-end-index-js" */),
  "component---src-pages-ordering-review-order-index-js": () => import("./../../../src/pages/ordering/review-order/index.js" /* webpackChunkName: "component---src-pages-ordering-review-order-index-js" */),
  "component---src-pages-ordering-shipping-index-js": () => import("./../../../src/pages/ordering/shipping/index.js" /* webpackChunkName: "component---src-pages-ordering-shipping-index-js" */),
  "component---src-pages-ordering-step-1-index-js": () => import("./../../../src/pages/ordering/step-1/index.js" /* webpackChunkName: "component---src-pages-ordering-step-1-index-js" */),
  "component---src-pages-ordering-step-2-index-js": () => import("./../../../src/pages/ordering/step-2/index.js" /* webpackChunkName: "component---src-pages-ordering-step-2-index-js" */),
  "component---src-pages-ordering-step-3-index-js": () => import("./../../../src/pages/ordering/step-3/index.js" /* webpackChunkName: "component---src-pages-ordering-step-3-index-js" */),
  "component---src-pages-ordering-step-4-index-js": () => import("./../../../src/pages/ordering/step-4/index.js" /* webpackChunkName: "component---src-pages-ordering-step-4-index-js" */),
  "component---src-pages-ordering-step-5-index-js": () => import("./../../../src/pages/ordering/step-5/index.js" /* webpackChunkName: "component---src-pages-ordering-step-5-index-js" */),
  "component---src-pages-ordering-step-6-index-js": () => import("./../../../src/pages/ordering/step-6/index.js" /* webpackChunkName: "component---src-pages-ordering-step-6-index-js" */),
  "component---src-pages-ordering-step-7-index-js": () => import("./../../../src/pages/ordering/step-7/index.js" /* webpackChunkName: "component---src-pages-ordering-step-7-index-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-purchase-index-js": () => import("./../../../src/pages/purchase/index.js" /* webpackChunkName: "component---src-pages-purchase-index-js" */),
  "component---src-pages-sign-in-index-js": () => import("./../../../src/pages/sign-in/index.js" /* webpackChunkName: "component---src-pages-sign-in-index-js" */),
  "component---src-pages-sign-up-index-js": () => import("./../../../src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-test-results-index-js": () => import("./../../../src/pages/test-results/index.js" /* webpackChunkName: "component---src-pages-test-results-index-js" */)
}

